<template>
  <div id="privacy">
    <div class="container">
      <div class="page-title">
        <div class="title-en">Privacy Policy</div>
        <h1 class="h2 title-zh">{{ privacy.title }}</h1>
      </div>
      <div v-html="privacy.content" class="content"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["menu"]),
    privacy() {
      let obj = null;
      this.menu.forEach((element) => {
        if (element.route == "/privacy") {
          obj = element;
        }
      });
      return obj;
    },
    mounted() {},
  },
};
</script>

<style lang="less" scoped>
#privacy {
  padding: 6em 5em;
  color: #461200;

  .mobile({
    padding: 3em 1em;

  });
  .page-title {
    padding: 35px 0 60px;
    border-bottom: 1px solid #ccc;
    .mobile({
        padding: 35px 0 30px;

    });
    .title-en {
      font-size: 4em;
      font-weight: bold;
      font-family: @f-tw;
      letter-spacing: 0.1em;
      .mobile({
        font-size: 2.5em;
      });
    }
    .title-zh {
      font-size: 24px;
    }
  }
  .content {
    padding: 1em 0;
    letter-spacing: 0.1em;
    line-height: 1.5em;
  }
}
</style>
